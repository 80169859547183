import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import moment from 'moment';
import {
  ButtonRow,
  FormInput,
  FormStepper,
  FormSelect,
  FormButtonGroup,
  FormDob,
  FormCheckBox,
  useTrackedFormik,
} from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import { MESSAGES, POPULATION_GROUP_OPTIONS } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';
import { ENROLMENT_STEPS, SEX_OPTIONS, HOSPTIAL_NUM_PREFIX } from '../../../../core/constants/forms.constant';
import * as validate from '../../../../core/utils/helpers/national-id.helper';
import { stepForward } from '../../../../store/enrolment';
import { formValueSelector } from '../../../../store/enrolment/selectors';
import { useAppDispatch, useAppSelector } from '../../../../store';

const validationSchema = yup.object().shape({
  dob: yup
    .string()
    .test('18Years', MESSAGES.underMinAge, (value) => {
      return moment().diff(moment(value), 'years') >= 18;
    })
    .required(MESSAGES.dobRequired),
  sex: yup.string().required(MESSAGES.sexRequired),
  ialchConfirm: yup
    .string()
    .oneOf([yup.ref('ialch')], MESSAGES.hospitalNumMismatch)
    .required(MESSAGES.hospitalNumConfirmRequired),
  nationalID: yup.string().test('NationalID', MESSAGES.nationalIDError, function (value) {
    return value ? validate.nationalID(value, this.parent.dob, this.parent.sex) : true;
  }),
  populationGroup: yup.string().required(MESSAGES.populationGroupRequired),
  selfDefinedEthnicity: yup.string().test('preferNotToSay', MESSAGES.ethnicityRequired, function (value) {
    return this.parent.preferNotToSay || value;
  }),
});

function Details1() {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector(formValueSelector);
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        dob: '',
        ialchConfirm: '',
        nationalID: '',
        populationGroup: '',
        selfDefinedEthnicity: '',
        preferNotToSay: false,
        sex: '',
        ...formValues,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentDetails2);
      },
    },
    'enrol-details1',
  );

  const onHospitalNumPrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(
      'ialchConfirm',
      e.target.value.startsWith(HOSPTIAL_NUM_PREFIX) ? e.target.value.slice(2) : e.target.value,
    );
  };

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={2} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Participant Details 1
        </Typography>

        <Box mb={8}>
          <Paper variant="outlined">
            <Box p={2}>
              <Typography variant="h5" component="h2" gutterBottom>
                Personal Details
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Please input the participant&apos;s personal details using the participant&apos;s appointment card where
                possible.
              </Typography>
              <FormDob
                error={formik.errors.dob}
                name="dob"
                label="Date of birth"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.dob}
                value={formik.values.dob}
                setValue={formik.setFieldValue}
                setTouched={formik.setFieldTouched}
              />
              <FormButtonGroup
                error={formik.errors.sex}
                name="sex"
                label="Sex"
                setValue={formik.setFieldValue}
                options={SEX_OPTIONS}
                hideLabel={false}
                touched={formik.touched.sex}
                value={formik.values.sex}
              />

              <FormInput
                error={formik.errors.ialchConfirm}
                name="ialchConfirm"
                label="Confirm IALCH ID number"
                onChange={onHospitalNumPrefixChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.ialchConfirm}
                value={formik.values.ialchConfirm}
                startAdornment={HOSPTIAL_NUM_PREFIX}
              />
              <FormInput
                error={formik.errors.nationalID}
                name="nationalID"
                label="National ID number (Optional)"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.nationalID}
                value={formik.values.nationalID}
              />
              <FormSelect
                error={formik.errors.populationGroup}
                name="populationGroup"
                label="Population group"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={POPULATION_GROUP_OPTIONS}
                placeholder=""
                touched={formik.touched.populationGroup}
                value={formik.values.populationGroup}
              />
              <FormInput
                error={formik.errors.selfDefinedEthnicity}
                name="selfDefinedEthnicity"
                label="Self-defined ethnicity"
                disabled={formik.values.preferNotToSay}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.selfDefinedEthnicity}
                value={formik.values.selfDefinedEthnicity}
                endAdornment={
                  <FormCheckBox
                    error={formik.errors.preferNotToSay}
                    name="preferNotToSay"
                    label={'Prefer not to say'}
                    onChange={(event: ChangeEvent) => {
                      formik.setFieldValue('selfDefinedEthnicity', '');
                      formik.handleChange(event);
                    }}
                    margin="none"
                    touched={formik.touched.preferNotToSay}
                    value={formik.values.preferNotToSay}
                  />
                }
              />
            </Box>
          </Paper>
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Details1;
